import {useEffect, useState} from "react";

import {useGetData} from "@src/hooks";
import {Suite} from "@src/models";

export const useGetSuites = () => {
    // La liste des données récupérées
    const [suites, setSuites] = useState(null);
    // La réponse de la requête API
    const {response} = useGetData("/api/v2/suites/", {});

    useEffect(() => {
        if (response) {
            const data = response.results.map(suite => new Suite(suite.id, suite.libelle));
            setSuites(data);
        }
    }, [response]);

    return {suites};
}
